// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.kpis {
    padding: 1rem 0;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.kpi-item {
    border: 1px solid #c3c3c3;
    border-radius: 15px;
    display: flex;
    flex-direction: row;
    width: 30%;
    margin: 30px 0;
    padding: 10px;
    padding: 40px 10px;
    align-items: center;
}

.kpi-item img {
    width: 25%;
    padding-right: 20px;
}

.kpi {
    display: flex;
    flex-direction: column;
}

.kpi .number {
    font-size: 1.6rem;
    font-weight: 600;
}

.kpi .text {
    color: #999999;
    font-size: 0.8rem;
    font-weight: 400;
}

@media (max-width: 768px) {
    .kpi-item {
        width: 100%;
        margin: 10px 0;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/kpi/kpi.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,aAAa;IACb,8BAA8B;IAC9B,eAAe;AACnB;;AAEA;IACI,yBAAyB;IACzB,mBAAmB;IACnB,aAAa;IACb,mBAAmB;IACnB,UAAU;IACV,cAAc;IACd,aAAa;IACb,kBAAkB;IAClB,mBAAmB;AACvB;;AAEA;IACI,UAAU;IACV,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,iBAAiB;IACjB,gBAAgB;AACpB;;AAEA;IACI,cAAc;IACd,iBAAiB;IACjB,gBAAgB;AACpB;;AAEA;IACI;QACI,WAAW;QACX,cAAc;IAClB;AACJ","sourcesContent":[".kpis {\n    padding: 1rem 0;\n    display: flex;\n    justify-content: space-between;\n    flex-wrap: wrap;\n}\n\n.kpi-item {\n    border: 1px solid #c3c3c3;\n    border-radius: 15px;\n    display: flex;\n    flex-direction: row;\n    width: 30%;\n    margin: 30px 0;\n    padding: 10px;\n    padding: 40px 10px;\n    align-items: center;\n}\n\n.kpi-item img {\n    width: 25%;\n    padding-right: 20px;\n}\n\n.kpi {\n    display: flex;\n    flex-direction: column;\n}\n\n.kpi .number {\n    font-size: 1.6rem;\n    font-weight: 600;\n}\n\n.kpi .text {\n    color: #999999;\n    font-size: 0.8rem;\n    font-weight: 400;\n}\n\n@media (max-width: 768px) {\n    .kpi-item {\n        width: 100%;\n        margin: 10px 0;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
