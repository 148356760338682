// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NewsLetter {
    border-radius: 20px;
    background-color: #FCCC4D;
    color: #363636;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem 2rem;
}

.NewsLetter .title {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 1rem;
    line-height: 40px;
}

.NewsLetter .subtitle {
    font-size: 1rem;
    font-weight: 400;
    padding: 10px 0;
    color: #666;
    margin-bottom: 1.5rem;
}

.content-section {
    flex: 1 1;
    padding-right: 2rem;
}

.img-section {
    flex: 1 1;
    display: flex;
    justify-content: center;
}

.img-section img {
    max-width: 100%;
    height: auto;
}

.subscribe {
    display: flex;
    align-items: center;
    gap: 1rem;
}

@media (max-width: 768px) {
    .NewsLetter {
        flex-direction: column;
        text-align: center;
    }

    .content-section {
        padding-right: 0;
        margin-bottom: 2rem;
    }

    /* .subscribe {
        flex-direction: column;
    } */
}`, "",{"version":3,"sources":["webpack://./src/components/newsLetter/newsLetter.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,yBAAyB;IACzB,cAAc;IACd,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,eAAe;IACf,iBAAiB;IACjB,mBAAmB;IACnB,iBAAiB;AACrB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,eAAe;IACf,WAAW;IACX,qBAAqB;AACzB;;AAEA;IACI,SAAO;IACP,mBAAmB;AACvB;;AAEA;IACI,SAAO;IACP,aAAa;IACb,uBAAuB;AAC3B;;AAEA;IACI,eAAe;IACf,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,SAAS;AACb;;AAEA;IACI;QACI,sBAAsB;QACtB,kBAAkB;IACtB;;IAEA;QACI,gBAAgB;QAChB,mBAAmB;IACvB;;IAEA;;OAEG;AACP","sourcesContent":[".NewsLetter {\n    border-radius: 20px;\n    background-color: #FCCC4D;\n    color: #363636;\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    padding: 2rem 2rem;\n}\n\n.NewsLetter .title {\n    font-size: 2rem;\n    font-weight: bold;\n    margin-bottom: 1rem;\n    line-height: 40px;\n}\n\n.NewsLetter .subtitle {\n    font-size: 1rem;\n    font-weight: 400;\n    padding: 10px 0;\n    color: #666;\n    margin-bottom: 1.5rem;\n}\n\n.content-section {\n    flex: 1;\n    padding-right: 2rem;\n}\n\n.img-section {\n    flex: 1;\n    display: flex;\n    justify-content: center;\n}\n\n.img-section img {\n    max-width: 100%;\n    height: auto;\n}\n\n.subscribe {\n    display: flex;\n    align-items: center;\n    gap: 1rem;\n}\n\n@media (max-width: 768px) {\n    .NewsLetter {\n        flex-direction: column;\n        text-align: center;\n    }\n\n    .content-section {\n        padding-right: 0;\n        margin-bottom: 2rem;\n    }\n\n    /* .subscribe {\n        flex-direction: column;\n    } */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
