// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("/public/assets/images/appBackground.webp", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Sora:wght@100;400;700&display=swap);"]);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `*{
  font-family: 'Sora', sans-serif;
}

body {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-repeat: repeat; 
  background-size: cover; 
  background-position: center; 
  margin: 0;
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AACA;EACE,+BAA+B;AACjC;;AAEA;EACE,yDAAiE;EACjE,yBAAyB;EACzB,sBAAsB;EACtB,2BAA2B;EAC3B,SAAS;AACX","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Sora:wght@100;400;700&display=swap');\n*{\n  font-family: 'Sora', sans-serif;\n}\n\nbody {\n  background-image: url('/public/assets/images/appBackground.webp');\n  background-repeat: repeat; \n  background-size: cover; \n  background-position: center; \n  margin: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
